$primary: #DD2D2D;


$line-height-base: 1.6;
$navbar-height: 100px;
$navbar-margin-bottom: 30px;
$code-color: #586E75;
$pre-color: #586E75;
$well-bg: #f8f8f8;
$code-bg: #f8f8f8;
$pre-bg: #f8f8f8;  
$body-bg: white;
$navbar-default-bg: #f8f8f8;
$navbar-default-border: #e7e7e7;
$blockquote-bg: #eeeef8;  
$nav-pills-active-link-hover-bg: $navbar-default-bg;
$nav-pills-link-active-color: black;
$nav-pills-link-active-bg: $navbar-default-bg;

$font-size-base: 1rem;

h1, .h1 { font-size: floor($font-size-base * 2.50); } /* ~34px */
h2, .h2 { font-size: floor($font-size-base * 2.15); } /* ~30px */
h3, .h3 { font-size: ceil($font-size-base * 1.70); }  /* ~24px */
h4, .h4 { font-size: ceil($font-size-base * 1.25); }  /* ~18px */
h5, .h5 { font-size:  $font-size-base; }
h6, .h6 { font-size: ceil($font-size-base * 0.85); }  /* ~12px */

@import "../bootstrap/scss/bootstrap";


/* general */

a {
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

.center {
     float: none;
     margin-left: auto;
     margin-right: auto;
}

.text-center {
     text-align: center;
}

.text-right {
     text-align: right;
}

/* logo */

.logo {
	width: 100px;
	height: 100px;
	padding: 5px;
}

.biglogo {
	width: 170px;
	height: 170px;
	padding: 5px;
}

.logobox {
	height: 170px;
	display: table-cell; 
	vertical-align: middle;
}

/* navbar */

#header {
    background-color: $navbar-default-bg;
    border-bottom: 1px solid $navbar-default-border;
}


.navbar-brand { 
	font-size: 50px; 
	font-weight: 100;
}
.navbar-nav { 
	font-size: 30px; 
	font-weight: 100;
}
.navbar-text {
	font-size: 15px;
	font-weight: 300;	
}

a.nav-link {
    color: $gray-900;
}

/* headings */

.bigtitle {
	font-size: 60px; 
	line-height: 55px;	
	font-weight: 100;
	/* Museo */	
}

.title {
	font-size: 36px; 
	line-height: 40px;	
	font-weight: 100;
	/* Museo */	
}

.bighead {
	font-size: 32px;
	font-weight: 300;
	line-height:36px;
	/* Museo */	
}

.head {
	font-size: 24px;
	font-weight: 300;
	line-height:30px;
	/* Museo */	
}

.smallhead {
	font-size: 19px;
	font-weight: 300;
	line-height: 23px;
	/* Museo */	
}

.note {
	font-size: 15px;
	font-weight: 300;
	line-height: 18px;
	/* Museo */	
}

.smallnote {
	font-size: 13px;
	font-weight: 300;
	line-height: 16px;
	/* Museo */	
}

.text-gray {
    color: #777777;
}

.text-dark-gray {
    color: gray;
}

/* other */

body {
	font-weight: 300;
}

p {
	font-weight: 300;
}

b, strong {
    font-weight: bold;
}
    

.hugespacer {
	height: 50px;
}

.bigspacer {
	height: 20px;
}

.spacer {
	height: 10px;
}

.smallspacer {
	height: 5px;
}

.headerspacer {
	height: 130px;
}

.footerspacer {
	height: 70px;
}

a.off { 
	color: inherit; 
}

a.foot {
	color: inherit;
}

a.foot:hover {
	color: gray;
}

pre {
    overflow-x: auto;
}

pre code {
    overflow-wrap: normal;
    white-space: pre;
}

table {
    @extend .table;
    @extend .table-striped;
}

img {
    @include img-fluid();
}

img.offset {
	float: right; 
	margin: 12px 0px 10px 15px;
}

img.avatar {
	width: 20px;
}

.post img {
     float: none;
     margin-left: auto;
     margin-right: auto;
}

blockquote {
    padding: calc(($line-height-base - 1) / 2);
    margin: 0 0 calc($line-height-base / 2);
    font-size: $font-size-base; 
    line-height: $line-height-base;
    border: 1px solid $border-color;
    border-radius: $border-radius;
    background-color: $blockquote-bg;

    p {
        font-size: ($font-size-base * 1.00);
        color: #777777;
    }
}

figure {
    text-align: center;
}

figcaption {
    color: #777777;
    text-align: center;
    font-size: 90%;
}

.breadcrumb {
	padding: 0px 0px;
	background-color: $body-bg;
	> li {
		&+li:before {  
			padding: 0px;
			content: "\00a0@{breadcrumb-separator}\00a0";
		}
		&+li:after {  
			padding: 0px;
		}
	}
}

.feedbox {
    height: 320px;
    overflow: hidden;    
}

.postbox {
	min-height: 80px;
}

.paperbox {
	min-height: 140px;
}

.projectbox {
	min-height: 120px;
}

.memberbox {
	min-height: 200px;
}

.talkbox {
	min-height: 100px;
}

.titlebox {
	min-width: 300px;    
}

.handlebox {
	height: 20px;
	display: table-cell; 
	vertical-align: middle;
}

.pad-right {
	padding-right: 10px;
}

.pad-left {
	padding-left: 10px;
}

@media (min-width: 992px) {
    #sidebar.sidebar-fixed {
        position: fixed;
    }
}

.footer {
	position: fixed;
	bottom: 0%;
	width: 100%;
	height: 60px;
	border: 1px solid transparent;
	border-color: $navbar-default-border;
	background-color: $navbar-default-bg;
}

.footernav {
    @extend .note;
    @extend .text-gray;
    text-align: center;
    margin-right: 2px;
    border: none;
    background-color: $navbar-default-bg;
}


/* Workshop programme styling */

table td.food {
    background-color: #cfc;
}

table td.social {
    background-color: orange;
}

table td.lecture {
    background-color: #ffc;
}

table td.help-session {
    background-color: #faa;
}

table td.invited-lecture {
    background-color: #fcf;
}

table td.tutorial {
    background-color: #ccf;
}

table td.padding {
    background-color: white;
    vertical-align: middle;
    text-align: center;
}


/* form style sheet */



/** Variables **/

:root {
  --color-background: #e7e7e7;
  --color-background-alt: #ba5ed7;
  --color-border-active: #a94bc3;
  --color-border-default: #d1d1d1;
  --color-highlight: #e2b6f1;
  --color-primary: #86319a;
  --color-primary-active: #5e2768;
  --color-text-default: #262626;
  --color-text-muted: #4f4f4f;

  --font-family-body: "Figtree", system-ui, sans-serif;
  --font-family-display: "Poppins", system-ui, sans-serif;
}

/** Base **/

*,
::before,
::after {
  box-sizing: border-box;
}

* {
  border: 0;
  margin: 0;
  padding: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  font-family: var(--font-family-body);
  font-optical-sizing: auto;
  font-style: normal;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

[type="checkbox"],
[type="radio"],
[type="range"] {
  appearance: none;
  flex-shrink: 0;
  padding: 0;
  user-select: none;
}

[type="checkbox"]:focus,
[type="radio"]:focus,
[type="range"]:focus {
  outline: none;
}

/** Components **/

.fs-form {
  display: grid;
  row-gap: 1rem;
}

.fs-form:where(.fs-layout__2-column) {
  column-gap: 0.75rem;
  grid-template-columns: 1fr 1fr;
}

fieldset {
  display: grid;
  row-gap: 1rem;
}

fieldset:where(.fs-layout__2-column) {
  column-gap: 0.75rem;
  grid-template-columns: 1fr 1fr;
}

.fs-form:where(.fs-layout__2-column) fieldset {
  column-gap: 0.75rem;
  grid-template-columns: 1fr 1fr;
  grid-column: 1 / -1;
}

.fs-field {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}

.fs-label {
  color: var(--color-text-default);
  display: block;
  font-family: var(--font-family-display);
  font-size: 1rem;
  line-height: 1.25rem;
}

.fs-description {
  color: var(--color-text-muted);
  display: block;
  font-size: 0.75rem;
  line-height: 1rem;
}

.fs-button-group {
  display: flex;
  flex-direction: row-reverse;
  column-gap: 0.75rem;
}

.fs-form:where(.fs-layout__2-column) .fs-button-group {
  grid-column: 1 / -1;
}

.fs-button {
  background-color: var(--color-primary);
  border-radius: 9999px;
  color: white;
  cursor: pointer;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5rem;
  padding: 0.75rem 2rem;
  transition-duration: 200ms;
  transition-property: background-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.fs-button:hover {
  background-color: var(--color-primary-active);
}

.fs-button:focus-visible {
  background-color: var(--color-primary-active);
  outline: 4px solid var(--color-highlight);
}

.fs-input,
.fs-select {
  appearance: none;
  border-radius: 9999px;
  border-width: 0;
  box-shadow: var(--color-border-default) 0 0 0 1px inset;
  color: var(--color-text-default);
  font-size: 0.875rem;
  height: 2.5rem;
  line-height: 1.25rem;
  outline: none;
  padding-left: 1rem;
  padding-right: 1rem;
}

.fs-input:focus-visible,
.fs-select:focus-visible {
  box-shadow: var(--color-border-active) 0 0 0 1px inset;
}

.fs-input::placeholder {
  color: var(--color-text-muted);
}

.fs-checkbox-group,
.fs-radio-group {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.fs-checkbox-field,
.fs-radio-field {
  column-gap: 0.5rem;
  display: flex;
}

:is(.fs-checkbox-field, .fs-radio-field) .fs-label + .fs-description {
  margin-top: 0.25rem;
}

.fs-checkbox-wrapper,
.fs-radio-wrapper {
  align-items: center;
  display: flex;
  height: 1.25rem;
}

.fs-checkbox,
.fs-radio {
  background-color: #fff;
  border: 1px solid var(--color-border-default);
  height: 1.25rem;
  width: 1.25rem;
}

.fs-checkbox {
  border-radius: 0.25rem;
}

.fs-radio {
  border-radius: 100%;
}

.fs-checkbox:checked,
.fs-radio:checked {
  background-color: var(--color-primary);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: transparent;
}

.fs-checkbox:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

.fs-radio:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}

.fs-checkbox:focus-visible,
.fs-radio:focus-visible {
  border-color: var(--color-border-active);
  outline: 4px solid var(--color-highlight);
  outline-offset: 0;
}

.fs-checkbox:checked:focus-visible,
.fs-radio:checked:focus-visible {
  border-color: transparent;
}

.fs-select {
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.75rem center;
  background-repeat: no-repeat;
  background-size: 1.625em 1.625em;
  padding-right: 2.875rem;
}

.fs-slider {
  background: transparent;
  cursor: pointer;
  height: 1.25rem;
  width: 100%;
}

.fs-slider::-moz-range-track {
  background-color: var(--color-background);
  border-radius: 0.5rem;
  height: 0.5rem;
}

.fs-slider::-webkit-slider-runnable-track {
  background-color: var(--color-background);
  border-radius: 0.5rem;
  height: 0.5rem;
}

.fs-slider::-moz-range-thumb {
  background-color: var(--color-primary);
  border: none; /* Removes extra border that FF applies */
  border-radius: 50%;
  height: 1.25rem;
  width: 1.25rem;
}

.fs-slider::-webkit-slider-thumb {
  appearance: none;
  background-color: var(--color-primary);
  border-radius: 50%;
  height: 1.25rem;
  margin-top: -0.375rem; /* Centers thumb on the track */
  width: 1.25rem;
}

.fs-slider:focus-visible::-moz-range-thumb {
  outline: 2px solid var(--color-primary);
  outline-offset: 2px;
}

.fs-slider:focus-visible::-webkit-slider-thumb {
  outline: 2px solid var(--color-primary);
  outline-offset: 2px;
}

.fs-switch-field {
  align-items: center;
  column-gap: 0.75rem;
  display: flex;
  justify-content: space-between;
}

.fs-switch {
  background-color: var(--color-background-alt);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2.75' fill='white'/%3e%3c/svg%3e");
  background-position: left center;
  background-repeat: no-repeat;
  border-radius: 1.25rem;
  cursor: pointer;
  height: 1.25rem;
  transition-duration: 200ms;
  transition-property: background-color, background-position;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  width: 2.5rem;
}

.fs-switch:checked {
  background-color: var(--color-primary);
  background-position: right center;
}

.fs-switch:focus-visible {
  outline: 4px solid var(--color-highlight);
  outline-offset: 0;
}

.fs-textarea {
  appearance: none;
  border-radius: 0.75rem;
  border-width: 0;
  box-shadow: var(--color-border-default) 0 0 0 1px inset;
  color: var(--color-text-default);
  font-size: 0.875rem;
  line-height: 1.25rem;
  outline: none;
  padding: 0.5rem 0.75rem;
  resize: vertical;
}

.fs-textarea:focus-visible {
  box-shadow: var(--color-border-active) 0 0 0 1px inset;
}

.fs-textarea::placeholder {
  color: var(--color-text-muted);
}


